<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :posts="posts" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/inspirations/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/inspirations';
import { createInspirationForm } from '@/constants/utils/form';
import { all } from '@/http/blog/posts';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },
  data() {
    return {
      posts: [],
    };
  },

  created() {
    this.fetchPosts();
  },

  methods: {
    async fetchPosts() {
      try {
        const { data } = await all();

        this.posts = data.data;
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił problem z pobraniem postów. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z pobraniem danych', message);
      }
    },

    async onSubmit(form) {
      const formData = createInspirationForm(form);

      try {
        await create(formData);

        this.showSuccessNotification('Dane zostały zapisane', 'Inspiracja została dodany.');
        this.$router.push({ name: 'inspirations-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił problem z zapisem inspiracji. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
